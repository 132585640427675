class ZIDXAccountDataFeeds implements ZIDXPage{
    constructor() {
    }
    getPageClass(){
        return "zidxAccountDataFeedsContainer";
    }
    getMatchURL(){
        return "/account/data-feeds";
    }
    render(){
        // console.log("data feeds");
    }
}